import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { isRetrieveUserCompleted, selectUser } from '@core/store/selectors/core.selectors';
import { map, Observable, skipWhile, take } from 'rxjs';
import { concatLatestFrom } from '@ngrx/operators';

export const authGuard: CanMatchFn = (route, segments): Observable<boolean | UrlTree> => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);

  return store.select(isRetrieveUserCompleted).pipe(
    skipWhile(isRetrieveUserCompleted => !isRetrieveUserCompleted),
    take(1),
    concatLatestFrom(() => store.select(selectUser).pipe(
      take(1),
      map(user => !!user)
    )),
    map(([, isLogged]) => isLogged || router.createUrlTree(['login']))
  );
};
